import { Country } from '../types/country';

export const countries: Country[] = [
  // Southeast Asia
  { name: 'Thailand', type: 'Visa on Arrival', processingTime: '24 hours', cost: '₹2,500', avgFlightPrice: '₹25,000', region: 'SEA' },
  { name: 'Vietnam', type: 'E-Visa', processingTime: '3-5 days', cost: '₹3,000', avgFlightPrice: '₹28,000', region: 'SEA' },
  { name: 'Singapore', type: 'E-Visa', processingTime: '3-4 days', cost: '₹4,500', avgFlightPrice: '₹32,000', region: 'SEA' },
  { name: 'Malaysia', type: 'E-Visa', processingTime: '48 hours', cost: '₹2,800', avgFlightPrice: '₹26,000', region: 'SEA' },
  { name: 'Indonesia', type: 'Visa on Arrival', processingTime: 'Immediate', cost: '₹2,500', avgFlightPrice: '₹30,000', region: 'SEA' },
  { name: 'Cambodia', type: 'E-Visa', processingTime: '3 days', cost: '₹2,600', avgFlightPrice: '₹35,000', region: 'SEA' },
  { name: 'Laos', type: 'Visa on Arrival', processingTime: 'Immediate', cost: '₹2,400', avgFlightPrice: '₹38,000', region: 'SEA' },
  { name: 'Philippines', type: 'E-Visa', processingTime: '5-7 days', cost: '₹3,500', avgFlightPrice: '₹32,000', region: 'SEA' },
  { name: 'Myanmar', type: 'E-Visa', processingTime: '3 days', cost: '₹3,200', avgFlightPrice: '₹29,000', region: 'SEA' },
  { name: 'Brunei', type: 'Visa on Arrival', processingTime: 'Immediate', cost: '₹2,700', avgFlightPrice: '₹40,000', region: 'SEA' },
  
  // European Union
  { name: 'France', type: 'Embassy Visa', processingTime: '15 days', cost: '₹7,500', avgFlightPrice: '₹45,000', region: 'EU' },
  { name: 'Germany', type: 'Embassy Visa', processingTime: '15 days', cost: '₹7,500', avgFlightPrice: '₹48,000', region: 'EU' },
  { name: 'Italy', type: 'Embassy Visa', processingTime: '15 days', cost: '₹7,500', avgFlightPrice: '₹46,000', region: 'EU' },
  { name: 'Spain', type: 'Embassy Visa', processingTime: '15 days', cost: '₹7,500', avgFlightPrice: '₹47,000', region: 'EU' },
  { name: 'Netherlands', type: 'Embassy Visa', processingTime: '15 days', cost: '₹7,500', avgFlightPrice: '₹49,000', region: 'EU' },
  { name: 'Portugal', type: 'Embassy Visa', processingTime: '15 days', cost: '₹7,500', avgFlightPrice: '₹50,000', region: 'EU' },
  { name: 'Greece', type: 'Embassy Visa', processingTime: '15 days', cost: '₹7,500', avgFlightPrice: '₹44,000', region: 'EU' },
  { name: 'Sweden', type: 'Embassy Visa', processingTime: '15 days', cost: '₹7,500', avgFlightPrice: '₹52,000', region: 'EU' },
  { name: 'Denmark', type: 'Embassy Visa', processingTime: '15 days', cost: '₹7,500', avgFlightPrice: '₹51,000', region: 'EU' },
  { name: 'Finland', type: 'Embassy Visa', processingTime: '15 days', cost: '₹7,500', avgFlightPrice: '₹53,000', region: 'EU' },
  { name: 'Belgium', type: 'Embassy Visa', processingTime: '15 days', cost: '₹7,500', avgFlightPrice: '₹48,000', region: 'EU' },
  { name: 'Austria', type: 'Embassy Visa', processingTime: '15 days', cost: '₹7,500', avgFlightPrice: '₹49,000', region: 'EU' },

  // North America
  { name: 'United States', type: 'Embassy Visa', processingTime: '30 days', cost: '₹10,000', avgFlightPrice: '₹60,000', region: 'NA' },
  { name: 'Canada', type: 'Embassy Visa', processingTime: '25 days', cost: '₹9,000', avgFlightPrice: '₹55,000', region: 'NA' },
  { name: 'Mexico', type: 'Visa on Arrival', processingTime: 'Immediate', cost: '₹5,000', avgFlightPrice: '₹50,000', region: 'NA' },

  // South America
  { name: 'Brazil', type: 'E-Visa', processingTime: '10 days', cost: '₹6,000', avgFlightPrice: '₹70,000', region: 'SA' },
  { name: 'Argentina', type: 'Visa on Arrival', processingTime: 'Immediate', cost: '₹5,500', avgFlightPrice: '₹65,000', region: 'SA' },
  { name: 'Chile', type: 'E-Visa', processingTime: '7 days', cost: '₹6,500', avgFlightPrice: '₹68,000', region: 'SA' },

  // Africa
  { name: 'South Africa', type: 'E-Visa', processingTime: '5 days', cost: '₹4,000', avgFlightPrice: '₹80,000', region: 'AF' },
  { name: 'Kenya', type: 'Visa on Arrival', processingTime: 'Immediate', cost: '₹3,500', avgFlightPrice: '₹75,000', region: 'AF' },
  { name: 'Egypt', type: 'E-Visa', processingTime: '4 days', cost: '₹4,500', avgFlightPrice: '₹70,000', region: 'AF' },

  // Oceania
  { name: 'Australia', type: 'E-Visa', processingTime: '20 days', cost: '₹8,000', avgFlightPrice: '₹90,000', region: 'OC' },
  { name: 'New Zealand', type: 'E-Visa', processingTime: '15 days', cost: '₹7,500', avgFlightPrice: '₹85,000', region: 'OC' },
  { name: 'Fiji', type: 'Visa on Arrival', processingTime: 'Immediate', cost: '₹6,000', avgFlightPrice: '₹95,000', region: 'OC' },

  // Middle East
  { name: 'United Arab Emirates', type: 'E-Visa', processingTime: '3 days', cost: '₹3,000', avgFlightPrice: '₹40,000', region: 'ME' },
  { name: 'Saudi Arabia', type: 'E-Visa', processingTime: '5 days', cost: '₹3,500', avgFlightPrice: '₹45,000', region: 'ME' },
  { name: 'Israel', type: 'E-Visa', processingTime: '7 days', cost: '₹4,000', avgFlightPrice: '₹50,000', region: 'ME' }
];