import React, { useState, useRef, useEffect } from 'react';
import { X, ChevronDown, MapPin, Globe } from 'lucide-react';

interface TableFiltersProps {
  selectedRegions: string[];
  selectedTypes: string[];
  onRegionChange: (region: string) => void;
  onTypeChange: (type: string) => void;
  onClearFilters: () => void;
  onApplyFilters: () => void;
  showClearButton: boolean;
}

export default function TableFilters({ selectedRegions, selectedTypes, onRegionChange, onTypeChange, onClearFilters, onApplyFilters, showClearButton }: TableFiltersProps) {
  const [isRegionDropdownOpen, setIsRegionDropdownOpen] = useState(false);
  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);
  const regionDropdownRef = useRef<HTMLDivElement>(null);
  const typeDropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (regionDropdownRef.current && !regionDropdownRef.current.contains(event.target as Node)) {
        setIsRegionDropdownOpen(false);
      }
      if (typeDropdownRef.current && !typeDropdownRef.current.contains(event.target as Node)) {
        setIsTypeDropdownOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const regions = ["SEA", "EU"];
  const types = ["Visa on Arrival", "E-Visa", "Embassy Visa"];

  return (
    <div className="flex flex-col space-y-4 px-6 py-3">
      <div className="flex space-x-4">
        <div className="relative w-full" ref={regionDropdownRef}>
          <input
            type="text"
            className="w-full h-14 pl-12 pr-4 rounded-full border border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-gray-900 text-lg shadow-sm bg-white"
            placeholder="Region"
            readOnly
            onClick={() => setIsRegionDropdownOpen(!isRegionDropdownOpen)}
          />
          <MapPin className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400 pointer-events-none" />
          <ChevronDown className="absolute right-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400 pointer-events-none" />
          {isRegionDropdownOpen && (
            <div className="absolute mt-2 w-full bg-white rounded-xl shadow-lg z-10 border border-gray-100">
              {regions.map(region => (
                <label key={region} className="block px-4 py-2 text-sm text-left">
                  <input
                    type="checkbox"
                    checked={selectedRegions.includes(region)}
                    onChange={() => onRegionChange(region)}
                    className="mr-2"
                  />
                  {region}
                </label>
              ))}
            </div>
          )}
        </div>
        <div className="relative w-full" ref={typeDropdownRef}>
          <input
            type="text"
            className="w-full h-14 pl-12 pr-4 rounded-full border border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-gray-900 text-lg shadow-sm bg-white"
            placeholder="Visa Type"
            readOnly
            onClick={() => setIsTypeDropdownOpen(!isTypeDropdownOpen)}
          />
          <Globe className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400 pointer-events-none" />
          <ChevronDown className="absolute right-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400 pointer-events-none" />
          {isTypeDropdownOpen && (
            <div className="absolute mt-2 w-full bg-white rounded-xl shadow-lg z-10 border border-gray-100">
              {types.map(type => (
                <label key={type} className="block px-4 py-2 text-sm text-left">
                  <input
                    type="checkbox"
                    checked={selectedTypes.includes(type)}
                    onChange={() => onTypeChange(type)}
                    className="mr-2"
                  />
                  {type}
                </label>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center space-x-4">
        {showClearButton && (
          <button
            className="flex items-center space-x-1 text-sm text-red-500"
            onClick={onClearFilters}
          >
            <X className="h-4 w-4" />
            <span>Clear</span>
          </button>
        )}
        <button
          className="w-full h-14 bg-[#F4A340] text-white font-medium rounded-full hover:bg-[#E89330] transition-colors flex items-center justify-center border-none"
          onClick={onApplyFilters}
        >
          Submit
        </button>
      </div>
    </div>
  );
}