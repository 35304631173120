import React from 'react';
import { useParams } from 'react-router-dom';
import { countries } from '../data/countries';

export default function CountryDetails() {
  const { country } = useParams<{ country: string }>();
  const countryDetails = countries.find(c => c.name.toLowerCase() === country?.toLowerCase());

  if (!countryDetails) {
    return <div className="text-center text-gray-600">Country not found</div>;
  }

  return (
    <div className="min-h-screen bg-white">
      <section className="pt-20 pb-16 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            Visa Requirements for {countryDetails.name}
          </h1>
          <p className="text-gray-600 text-lg mb-8">
            Get instant information about visa requirements, processing times, and costs for {countryDetails.name}
          </p>
        </div>
      </section>
      <section className="px-4 pb-20">
        <div className="max-w-6xl mx-auto">
          <div className="bg-white rounded-xl shadow-xl p-6">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Visa Information</h2>
            <p className="text-gray-600 mb-4">Visa Type: {countryDetails.type}</p>
            <p className="text-gray-600 mb-4">Processing Time: {countryDetails.processingTime}</p>
            <p className="text-gray-600 mb-4">Visa Cost: {countryDetails.cost}</p>
            <p className="text-gray-600 mb-4">Average Flight Price: {countryDetails.avgFlightPrice}</p>
            <div className="mt-4 flex space-x-4">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                onClick={() => window.open(`https://official-visa-page.com/${countryDetails.name.toLowerCase()}`, '_blank')}
              >
                Apply for Visa
              </button>
              <button
                className="px-4 py-2 bg-green-500 text-white rounded-lg"
                onClick={() => window.open(`https://www.skyscanner.net/transport/flights/${countryDetails.name.toLowerCase()}`, '_blank')}
              >
                Book Flights
              </button>
              <button
                className="px-4 py-2 bg-yellow-500 text-white rounded-lg"
                onClick={() => alert(`${countryDetails.name} added to wishlist`)}
              >
                Save to Wishlist
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
