import React, { useState, useRef, useEffect } from 'react';
import CountryInput from './CountryInput';
import SearchResults from './SearchResults';
import { countries } from '../../data/countries';
import { useNavigate } from 'react-router-dom';

export default function SearchBox({ onSearch }: { onSearch: (country: string) => void }) {
  console.log('SearchBox component rendered');
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filteredCountries = countries.filter(country => country.name.toLowerCase().includes(search.toLowerCase()));

  return (
    <div className="max-w-2xl mx-auto mt-12 space-y-4" ref={wrapperRef}>
      <CountryInput
        value={search}
        onChange={setSearch}
        onFocus={() => setIsOpen(true)}
      />

      {isOpen && (
        <SearchResults
          results={filteredCountries}
          onSelect={(country) => {
            setSearch(country.name);
            setIsOpen(false);
          }}
        />
      )}

      <button
        className="w-full h-14 bg-[#F4A340] text-white font-medium rounded-full hover:bg-[#E89330] transition-colors"
        onClick={() => {
          const selectedCountry = countries.find(country => country.name.toLowerCase() === search.toLowerCase());
          if (selectedCountry) {
            onSearch(selectedCountry.name.toLowerCase());
          }
        }}
      >
        Get Visa Requirements for Indians
      </button>
    </div>
  );
}