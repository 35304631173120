import React, { useState } from 'react';
import { countries } from '../../data/countries';
import TableFilters from './TableFilters';
import { Plane, Clock, CreditCard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function DestinationTable() {
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: string, direction: 'asc' | 'desc' } | null>(null);
  const navigate = useNavigate();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [showResults, setShowResults] = useState(false);

  const onRegionChange = (region: string) => {
    setSelectedRegions(prev => prev.includes(region) ? prev.filter(r => r !== region) : [...prev, region]);
  };

  const onTypeChange = (type: string) => {
    setSelectedTypes(prev => prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]);
  };

  const onClearFilters = () => {
    setSelectedRegions([]);
    setSelectedTypes([]);
  };

  const onApplyFilters = () => {
    setShowResults(true);
  };

  const sortedCountries = [...countries].sort((a, b) => {
    if (!sortConfig) return 0;
    const { key, direction } = sortConfig;
    const order = direction === 'asc' ? 1 : -1;
    if (typeof a[key] === 'string') {
      return a[key].localeCompare(b[key]) * order;
    }
    return (a[key] - b[key]) * order;
  });

  const filteredCountries = sortedCountries.filter(country => {
    if (selectedRegions.length && !selectedRegions.includes(country.region)) return false;
    if (selectedTypes.length && !selectedTypes.includes(country.type)) return false;
    return true;
  });

  const requestSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="bg-white rounded-2xl shadow-sm border border-gray-100">
      <TableFilters
        selectedRegions={selectedRegions}
        selectedTypes={selectedTypes}
        onRegionChange={onRegionChange}
        onTypeChange={onTypeChange}
        onClearFilters={onClearFilters}
        onApplyFilters={onApplyFilters}
        showClearButton={showResults}
      />
      {showResults && (
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b border-gray-100">
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500 cursor-pointer" onClick={() => requestSort('name')}>
                  Country {sortConfig?.key === 'name' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500 cursor-pointer" onClick={() => requestSort('type')}>
                  Visa Type {sortConfig?.key === 'type' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500 cursor-pointer" onClick={() => requestSort('processingTime')}>
                  <div className="flex items-center space-x-2">
                    <Clock className="h-4 w-4" />
                    <span>Processing Time {sortConfig?.key === 'processingTime' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}</span>
                  </div>
                </th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500 cursor-pointer" onClick={() => requestSort('cost')}>
                  <div className="flex items-center space-x-2">
                    <CreditCard className="h-4 w-4" />
                    <span>Visa Cost {sortConfig?.key === 'cost' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}</span>
                  </div>
                </th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500 cursor-pointer" onClick={() => requestSort('avgFlightPrice')}>
                  <div className="flex items-center space-x-2">
                    <Plane className="h-4 w-4" />
                    <span>Avg. Flight {sortConfig?.key === 'avgFlightPrice' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredCountries.map((country, index) => (
                <React.Fragment key={index}>
                  <tr className="border-b border-gray-50 hover:bg-gray-50 transition-colors">
                    <td className="px-6 py-4">
                      <span
                        className="font-medium text-gray-900 cursor-pointer"
                        onClick={() => navigate(`/india/${country.name.toLowerCase()}`)}
                      >
                        {country.name}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      <span className="inline-block px-2 py-1 rounded-full bg-blue-50 text-blue-600 text-xs font-medium">
                        {country.type}
                      </span>
                    </td>
                    <td className="px-6 py-4 text-gray-600">{country.processingTime}</td>
                    <td className="px-6 py-4 text-gray-600">{country.cost}</td>
                    <td className="px-6 py-4 text-gray-600">
                      <button
                        className="text-sm text-blue-500"
                        onClick={() => setExpandedIndex(expandedIndex === index ? null : index)}
                      >
                        {expandedIndex === index ? 'Less Details' : 'More Details'}
                      </button>
                    </td>
                  </tr>
                  {expandedIndex === index && (
                    <tr className="border-b border-gray-50">
                      <td colSpan={5} className="px-6 py-4 text-gray-600">
                        <div className="flex items-center space-x-2">
                          <Plane className="h-4 w-4" />
                          <span className="text-sm font-medium">{country.avgFlightPrice}</span>
                        </div>
                        <div className="mt-4 flex space-x-4">
                          <button
                            className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(`https://official-visa-page.com/${country.name.toLowerCase()}`, '_blank');
                            }}
                          >
                            Apply for Visa
                          </button>
                          <button
                            className="px-4 py-2 bg-green-500 text-white rounded-lg"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(`https://www.skyscanner.net/transport/flights/${country.name.toLowerCase()}`, '_blank');
                            }}
                          >
                            Book Flights
                          </button>
                          <button
                            className="px-4 py-2 bg-yellow-500 text-white rounded-lg"
                            onClick={(e) => {
                              e.stopPropagation();
                              // Implement save to wishlist functionality
                              alert(`${country.name} added to wishlist`);
                            }}
                          >
                            Save to Wishlist
                          </button>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}