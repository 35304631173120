import React from 'react';
import { Globe } from 'lucide-react';

interface CountryInputProps {
  value: string;
  onChange: (value: string) => void;
  onFocus: () => void;
}

export default function CountryInput({ value, onChange, onFocus }: CountryInputProps) {
  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-4 flex items-center pointer-events-none">
        <Globe className="h-5 w-5 text-gray-400" />
      </div>
      <input
        type="text"
        className="w-full h-14 pl-12 pr-4 rounded-full border border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-gray-900 text-lg shadow-sm"
        placeholder="your destination country"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={onFocus}
      />
      <div className="absolute inset-y-0 right-4 flex items-center pointer-events-none">
        <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </div>
    </div>
  );
}