import React, { useState } from 'react';
import { MapPin, Plane } from 'lucide-react';
import { Country } from '../../types/country';
import { useNavigate } from 'react-router-dom';

interface SearchResultsProps {
  results: Country[];
  onSelect: (country: Country) => void;
}

export default function SearchResults({ results, onSelect }: SearchResultsProps) {
  const navigate = useNavigate();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  if (results.length === 0) {
    return (
      <div className="absolute w-full bg-white rounded-xl shadow-lg z-50 border border-gray-100">
        <div className="p-4 text-center text-gray-500">No countries found</div>
      </div>
    );
  }

  return (
    <div className="absolute w-full bg-white rounded-xl shadow-lg max-h-[60vh] overflow-auto z-50 border border-gray-100">
      {results.map((country, index) => (
        <React.Fragment key={index}>
          <div
            className="group p-4 hover:bg-blue-50 cursor-pointer border-b last:border-b-0 transition-all"
            onClick={() => navigate(`/india/${country.name.toLowerCase()}`)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div className="p-2 rounded-full bg-blue-50 group-hover:bg-white transition-colors">
                  <MapPin className="h-5 w-5 text-blue-500" />
                </div>
                <div>
                  <span className="font-medium text-gray-900">{country.name}</span>
                  <div className="flex items-center space-x-2 mt-1">
                    <span className="px-2 py-1 rounded-full bg-blue-100 text-blue-700 text-xs font-medium">
                      {country.type}
                    </span>
                    <span className="text-sm text-gray-500">
                      Processing: {country.processingTime}
                    </span>
                  </div>
                </div>
              </div>
              <button
                className="text-sm text-blue-500"
                onClick={(e) => {
                  e.stopPropagation();
                  setExpandedIndex(expandedIndex === index ? null : index);
                }}
              >
                {expandedIndex === index ? 'Less Details' : 'More Details'}
              </button>
            </div>
          </div>
          {expandedIndex === index && (
            <div className="p-4 text-gray-600 border-b last:border-b-0">
              <div className="flex items-center space-x-2">
                <Plane className="h-4 w-4" />
                <span className="text-sm font-medium">{country.avgFlightPrice}</span>
              </div>
              <div className="mt-4 flex space-x-4">
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`https://official-visa-page.com/${country.name.toLowerCase()}`, '_blank');
                  }}
                >
                  Apply for Visa
                </button>
                <button
                  className="px-4 py-2 bg-green-500 text-white rounded-lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`https://www.skyscanner.net/transport/flights/${country.name.toLowerCase()}`, '_blank');
                  }}
                >
                  Book Flights
                </button>
                <button
                  className="px-4 py-2 bg-yellow-500 text-white rounded-lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    // Implement save to wishlist functionality
                    alert(`${country.name} added to wishlist`);
                  }}
                >
                  Save to Wishlist
                </button>
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}