import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import SearchBox from './components/SearchBox';
import DestinationTable from './components/Table';
import CountryDetails from './components/CountryDetails';

export default function App() {
  console.log('App component rendered');

  const navigate = useNavigate();

  const handleSearch = (country: string) => {
    navigate(`/india/${country}`);
  };

  return (
    <div className="min-h-screen bg-cover bg-center" style={{ backgroundImage: 'url(/background.jpg)' }}>
      <Routes>
        <Route path="/" element={
          <>
            <section className="pt-20 pb-16 px-4 flex flex-col items-center justify-start min-h-screen">
              <div className="max-w-4xl mx-auto text-center" style={{ marginTop: '10vh' }}>
                <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
                  Indian Passport? <br />
                  <span className="text-teal-500">Find visa requirements</span>{' '}
                  <span className="text-blue-500">for any country</span>
                </h1>
                <p className="text-gray-600 text-lg mb-8">
                  Get instant information about visa requirements, processing times, and costs
                </p>
                <SearchBox onSearch={handleSearch} />
              </div>
              <div className="text-center my-8" style={{ marginTop: '10vh' }}>
                <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">OR</h2>
                <p className="text-lg text-gray-600 mb-8">Explore destinations</p>
                <div className="max-w-2xl mx-auto">
                  <DestinationTable />
                </div>
              </div>
            </section>
          </>
        } />
        <Route path="/india/:country" element={<CountryDetails />} />
      </Routes>
    </div>
  );
}